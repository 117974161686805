import loadable from '@loadable/component';

const DownloadPaymentAdviceReport = loadable(
  () => import('./DownloadPaymentAdviceReportPdf'),
);

const DownloadPaymentReceiptReport = loadable(
  () => import('./DownloadPaymentReceiptReportPdf'),
);

export const downloadPdf = [
  {
    path: '/download-payment-advice-report/:id',
    component: DownloadPaymentAdviceReport,
    title: 'Download Payment Advice',
    exact: true,
  },
  {
    path: '/download-payment-receipt-report/:id',
    component: DownloadPaymentReceiptReport,
    title: 'Download Payment Receipt',
    exact: true,
  },
];
