import { apiService } from '@services/api';
import {
  IChangePasswordUserReq,
  IGetGantryRecordReq,
} from 'src/interfaces/network';

const getProfile = async () => await apiService.get(`/users/me`);

const suggestUsername = async ({ firstName, lastName }: any) =>
  await apiService.get(
    `/users/username/suggest?firstName=${firstName}&lastName=${lastName}`,
  );

const sendEmailForgotPassword = async (email: string) =>
  await apiService.patch(`/users/${email}/forget-password`);

const forgotPasswordChange = async (request: {
  code: string;
  newPassword: string;
}) => await apiService.patch(`/users/confirm-forget-password`, request);

const unlockAccountUser = async (id: string) =>
  await apiService.patch(`/users/${id}/unblock`);

const changePasswordUser = async (
  id: string,
  request: IChangePasswordUserReq,
  token?: string,
) => {
  if (token) {
    localStorage.removeItem('idg');
    return await apiService.patch(`/users/${id}/change-password`, request, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return await apiService.patch(`/users/${id}/change-password`, request);
};

const deleteAccountUser = async (id: string) =>
  await apiService.delete(`/users/${id}`);

const createUser = async (request: any) =>
  await apiService.post(`/users`, request);

const updateUser = async (id: string, request: any) =>
  await apiService.put(`/users/${id}`, request);

const getUserGantryRecord = async (request?: IGetGantryRecordReq) =>
  await apiService.get(`/users/gantry-records`, {
    params: request,
  });

export {
  getProfile,
  suggestUsername,
  sendEmailForgotPassword,
  forgotPasswordChange,
  unlockAccountUser,
  changePasswordUser,
  deleteAccountUser,
  createUser,
  updateUser,
  getUserGantryRecord,
};
