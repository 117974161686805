import loadable from '@loadable/component';

const StudentProfile = loadable(() => import('./pages/StudentProfile'));
const EditProfile = loadable(() => import('./pages/EditProfile'));

export default [
  {
    path: '/my-profile',
    component: StudentProfile,
    title: 'My Profile',
    breadcrumbs: [],
    exact: true,
  },
  {
    path: '/my-profile/edit',
    component: EditProfile,
    title: 'Edit Profile',
    breadcrumbs: [],
    exact: true,
  },
];
