import { transactionIdVar } from '@services/graphql/core';
import { useReactiveVar } from '@apollo/client';

export interface ITransaction {
  transactionId: string | null;
  backURL: string | null;
  redirectURL: string | null;
}

export function useTransaction(): ITransaction {
  const transactionId = useReactiveVar(transactionIdVar);
  const query = new URLSearchParams(transactionId);

  return {
    transactionId: query.get('transaction_id'),
    backURL: query.get('back_url'),
    redirectURL: query.get('redirect_url'),
  };
}
