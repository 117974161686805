import loadable from '@loadable/component';

const ChangePassword = loadable(() => import('./pages/ChangePassword'));
const ForgotPassword = loadable(() => import('./pages/ForgotPassword'));
const Login = loadable(() => import('./pages/Login'));

export default [
  {
    path: '/login',
    component: Login,
    title: 'Login Page',
    exact: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    title: 'Forgot password page',
    exact: true,
  },
];

export const changePasswordRoute = [
  {
    path: '/change-password/:email',
    component: ChangePassword,
    title: 'Forgot password page',
    exact: true,
  },
];
