import { Header, Navigation } from '@components/layouts';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { IRoute } from './index';
import { Layout } from 'antd';
import styled from 'styled-components';
import { transactionIdVar } from '@services/graphql/core';

const Main = styled(Layout.Content)`
  margin-top: 60px;
  padding: 20px;
  background-color: #fafbfd;
  min-height: 400px;
`;

interface PrivateRouteProps {
  isLoggedIn: boolean;
  history?: any;
  title: string;
  children: ReactNode;
  privateRoutes: IRoute[];
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  isLoggedIn,
  history,
  title,
  children,
  privateRoutes,
  ...props
}) => {
  const [collapsed, setCollapse] = useState(
    !!('ontouchstart' in window || navigator?.msMaxTouchPoints),
  );
  const { pathname, search } = useLocation();

  const _handleToggleCollapse = () => setCollapse(!collapsed);

  if (search.indexOf('transaction_id') > -1) {
    transactionIdVar(search);
  }

  const renderChildren = () => {
    if (pathname === '/' && isLoggedIn) {
      return <Redirect to="/classes" />;
    }

    if (history?.location?.pathname === '/' && !isLoggedIn) {
      return <Redirect to="/login" />;
    }

    return (
      <Route
        {...props}
        render={({ location, match }) => {
          const crumbs = privateRoutes
            .filter(({ path }) => match.path.includes(path))
            .map(({ path, ...rest }) => ({
              path: Object.keys(match.params).length
                ? Object.keys(match.params).reduce(
                    (path, param) =>
                      path.replace(`:${param}`, match.params[param] as string),
                    path,
                  )
                : path,
              ...rest,
            }));

          return isLoggedIn ? (
            <>
              <Header
                title={title}
                breadcrumbs={crumbs}
                isCollapse={collapsed}
                toggleCollapse={_handleToggleCollapse}
              />
              {children}
            </>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );
        }}
      />
    );
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Layout>
        <Navigation
          collapsed={collapsed}
          toggleCollapse={_handleToggleCollapse}
        />
        <Main id="scrollableDiv">{renderChildren()}</Main>
      </Layout>
    </Layout>
  );
};

export default PrivateRoute;
