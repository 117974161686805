import FileSaver from 'file-saver';

import { apiService } from '@services/api';

export const getFileName = (url: any, getExtension = false) => {
  const fileName = url.split('/').pop();

  if (getExtension) {
    return fileName;
  }

  return fileName.replace(/\.[^/.]+$/, '');
};

export const parameterizeArray = (key: string, arr: any) => {
  return (
    '?' + key + '[]=' + arr.map(encodeURIComponent).join('&' + key + '[]=')
  );
};

export const upload: any = {
  downloadFile: async (url: string, name: string) => {
    return FileSaver.saveAs(url, name);
  },
  getFileUrl: (key: string, type: any) => {
    return `${process.env.REACT_APP_REST_API_URL}/media/${
      type === 'image' ? 'public' : 'download'
    }?key=${key}`;
  },
  normFile: (mode: string) => (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    if (e.fileList.length === 0) {
      return [];
    }

    if (mode === 'single') {
      return e.file;
    }

    return e.fileList.map((item: any) => {
      if (item.url) {
        return item;
      }
      return item.originFileObj;
    });
  },
  setDefaultFileList: (input: any) => {
    if (!input || input instanceof File) {
      return [];
    }

    if (Array.isArray(input)) {
      if (input.some(file => file instanceof File)) {
        return [];
      }

      return input.map(url => {
        if (typeof url === 'string') {
          const name = getFileName(url, true);

          return { uid: name, url: upload.getFileUrl(url, 'file'), name };
        }

        return {
          uid: url.name,
          url: upload.getFileUrl(url.fileUrl, 'file'),
          name: url.name,
        };
      });
    } else {
      const name = getFileName(input, true);

      return [{ uid: name, url: upload.getFileUrl(input, 'file'), name }];
    }
  },
  singleFileUpload: async (file: any) => {
    try {
      return new Promise(async (resolve, reject) => {
        const response = await apiService.get(
          `/media/put-presigned-url?key=${file?.name}`,
        );

        await fetch(response.data.url, {
          method: 'PUT',
          body: file,
        })
          .then(() => resolve({ success: true, link: response.data.keyPath }))
          .catch(err => resolve({ success: false, message: err.message }));
      });
    } catch (err) {
      throw new Error('Upload failed!');
    }
  },
  triggerSingleUpload: async (file: any) => {
    let result = null;

    if (file) {
      if (typeof file === 'string') {
        result = file;
      } else {
        const uploadedImage: any = await upload.singleFileUpload(file);

        if (uploadedImage.success) {
          result = uploadedImage.link;
        }
      }
    }

    return result;
  },
  multipleFileUpload: async (fileList: any) => {
    return Promise.all(
      fileList.map((file: any) => upload.singleFileUpload(file)),
    );
  },
  triggerMultipleUpload: async (fileList: any) => {
    let result = null;

    if (fileList) {
      result = [];
      const imagesToUpload = fileList.filter(
        (image: any) => typeof image !== 'string' && !image.url,
      );
      result = fileList
        .filter((image: any) => typeof image === 'string' || image.url)
        .map((image: any) => (typeof image === 'string' ? image : image.url));

      if (imagesToUpload.length > 0) {
        const uploadedImages = await upload.multipleFileUpload(imagesToUpload);

        if (uploadedImages) {
          result.push(...uploadedImages.map((image: any) => image.link));
        }
      }
    }

    return result;
  },
};
