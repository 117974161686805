export * from './global.styled';
export const theme = {
  color: {
    primary: '#2b368b',
    secondary: '#828894',
    black: '#000000',
    white: '#fff',
    link: '#2b368b',
    tag: '#538dff',
    line: '#d8dce6',
    success: '#65db75',
    warning: '#edc20f',
    dangerous: '#f54f64',
  },
  fontSize: {
    small: 12,
    base: 14,
    medium: 18,
    large: 24,
    xl: 32,
  },
  spacing: {
    xs: 4,
    sm: 8,
    md: 16,
    lg: 20,
    xl: 40,
  },
  breakpoints: {
    xs: '576px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
  },
  fontFamily: {
    regular: 'Inter-Regular',
    bold: 'Inter-SemiBold',
  },
  sidebar: {
    width: 200,
  },
};
