import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '@ant-design/icons/lib/components/Icon';
import React from 'react';

export interface MenuItem {
  label?: string;
  href?: string;
  subMenu?: MenuItem[];
  icon?: React.ReactNode;
  key: string;
}

const menus: MenuItem[] = [
  // {
  //   label: 'Dashboard',
  //   icon: (
  //     <Icon viewBox={'0, 0, 1024, 1024'}>
  //       <FontAwesomeIcon icon={['far', 'user-friends']} />
  //     </Icon>
  //   ),
  //   href: '/dashboard',
  // },
  {
    label: 'Profile',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['fas', 'portrait']} />
      </Icon>
    ),
    href: '/my-profile',
    key: 'my-profile',
  },
  {
    label: 'Class Schedule',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'users-class']} />
      </Icon>
    ),
    href: '/classes',
    key: 'classes',
  },
  {
    label: 'Transfer History',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['fas', 'exchange']} />
      </Icon>
    ),
    href: '/transfer-class',
    key: 'transfer-class',
  },
  {
    label: 'Billing Information',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'money-check-edit-alt']} />
      </Icon>
    ),
    href: '/pay-bill',
    key: 'pay-bill',
  },
  {
    label: 'Announcements',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'book-reader']} />
      </Icon>
    ),
    href: '/announcements',
    key: 'announcements',
  },
  // {
  //   label: 'Graduate Class',
  //   icon: (
  //     <Icon viewBox={'0, 0, 1024, 1024'}>
  //       <FontAwesomeIcon icon={['far', 'book-reader']} />
  //     </Icon>
  //   ),
  //   href: '/graduate-class',
  //   key: 'graduate-class',
  // },
];

export default menus;
