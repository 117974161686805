import axios from 'axios';
import * as http from 'http';

export const apiService = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  httpAgent: new http.Agent({ keepAlive: true }),
});

export const axiosPublic = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiService.interceptors.request.use(
  async function (config) {
    const session = JSON.parse(localStorage.getItem('idg') as string);
    if (session?.accessToken) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${session.accessToken.jwtToken}`,
      };
    }

    return config;
  },
  function (error) {
    return error.response;
  },
);

let isRefreshing = false;
let refreshSubscribers: any[] = [];

apiService.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const config = error?.config;
    if (error?.response?.status === 401 && !config.__isRetryRequest) {
      if (!isRefreshing) {
        isRefreshing = true;
        const session = JSON.parse(localStorage.getItem('idg') as any);
        const { data: response } = await axiosPublic.get(
          `/auth/access-token?username=${session.accessToken.payload.username}&refreshToken=${session.refreshToken.token}`,
        );
        const { data, success } = response || {};
        if (success) {
          if (data?.accessToken) {
            localStorage.setItem('idg', JSON.stringify(data));
          }
        }
        isRefreshing = false;
        refreshSubscribers.forEach(cb => cb(data.accessToken.jwtToken));
        refreshSubscribers = [];
      }
      const retryOriginalRequest = new Promise(resolve => {
        addRefreshSubscriber((token: any) => {
          config.headers.authorization = `Bearer ${token}`;
          resolve(apiService(config));
        });
      });
      return retryOriginalRequest;
    }
    return Promise.reject(error.response.data);
  },
);

function addRefreshSubscriber(cb: any) {
  refreshSubscribers.push(cb);
}
