import { Breadcrumb, Col, Row } from 'antd';
import React, { FunctionComponent } from 'react';

import { Link } from 'react-router-dom';
import Styled from './Header.styled';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router';

export interface HeaderProps {
  breadcrumbs: any[];
  title: string;
  isCollapse: boolean;
  toggleCollapse: () => void;
}

export const Header: FunctionComponent<HeaderProps> = ({
  breadcrumbs,
  title,
  isCollapse,
  toggleCollapse,
}) => {
  const history = useHistory();
  const firstPage = !isEmpty(breadcrumbs) ? head(breadcrumbs) : null;
  const restPage = !isEmpty(breadcrumbs.slice(0)) ? breadcrumbs.slice(0) : null;
  return (
    <Styled.Header
      className="header-wrap"
      collapsed={isCollapse ? 'collapsed' : undefined}
      line={restPage ? 'exist' : 'non'}
    >
      <Row gutter={[7, 0]} align="middle">
        <Col>
          <div className="breadcrumb-wrapper">
            {firstPage && (
              <span className="breadcrumb-page">{firstPage.title}</span>
            )}
            <Breadcrumb>
              {restPage &&
                restPage.map((e: any, index: number) => (
                  <Breadcrumb.Item key={`${e.title}-${index}`}>
                    {e.path && e.title !== 'Home' ? (
                      e.path === 'goback' ? (
                        <a
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          {e.title}
                        </a>
                      ) : e.path === '*' ? (
                        <Link to="/">{e.title}</Link>
                      ) : (
                        <Link to={e.path}>{e.title}</Link>
                      )
                    ) : null}
                  </Breadcrumb.Item>
                ))}
            </Breadcrumb>
          </div>
        </Col>
      </Row>
    </Styled.Header>
  );
};
