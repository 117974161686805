import { InMemoryCache, ReactiveVar, makeVar } from '@apollo/client';

export interface IAnnouncements {
  count: {
    news: 0;
    broadcast: 0;
  };
  isClickAnnouncements: boolean;
}

const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        auth: {
          read() {
            return authVar();
          },
        },
        transactionId: {
          read() {
            return transactionIdVar();
          },
        },
        announcements: {
          read() {
            return announcementsVar();
          },
        },
      },
    },
  },
});

export const authVar: ReactiveVar<any> = makeVar<any>({ isLoggedIn: false });
export const transactionIdVar: ReactiveVar<string> = makeVar<string>('');
export const announcementsVar: ReactiveVar<IAnnouncements> =
  makeVar<IAnnouncements>({
    count: {
      news: 0,
      broadcast: 0,
    },
    isClickAnnouncements: false,
  });

export default cache;
