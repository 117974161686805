import React from 'react';
import { useAuth } from './useAuth';
import {
  useOnBroadcastAddedSubscription,
  useOnNewsAddedSubscription,
} from '@services/data-access';
import { apiService } from '@services/api';
import { useAnnouncements } from './announcements';
import { Spin } from 'antd';

export const withAuth = (Component: React.ComponentType<any>) => {
  return (props: any) => {
    const [{ id }, setAuth] = useAuth();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [announcements, setAnnouncementCount] = useAnnouncements();

    useOnBroadcastAddedSubscription({
      variables: { id },
      onData: ({ data }) => {
        const count = data?.data?.broadcast_logs_aggregate?.aggregate?.count;

        if (!!count) {
          setAnnouncementCount({
            ...announcements,
            count: {
              ...announcements.count,
              broadcast: count as any,
            },
          });
        }
      },
    });

    useOnNewsAddedSubscription({
      variables: { id },
      onData: ({ data }) => {
        const count = data?.data?.news_logs_aggregate?.aggregate?.count;
        if (!!count) {
          setAnnouncementCount({
            ...announcements,
            count: {
              ...announcements.count,
              news: count as any,
            },
          });
        }
      },
    });

    React.useEffect(() => {
      document.title = `Indigo ${
        process.env.NODE_ENV !== 'production' ? `(${process.env.NODE_ENV})` : ''
      }`;
      (async () => {
        try {
          if (!!localStorage.getItem('idg')) {
            setIsLoading(true);
            const { data: response, status } = await apiService.get(
              '/users/me',
            );
            if (status === 200) {
              setAuth({ ...(response.data || {}), isLoggedIn: true });
            }
            setIsLoading(false);
          }
        } catch (err) {
          setIsLoading(false);
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
      return <Spin />;
    }

    return <Component {...props} />;
  };
};
