import React, { FunctionComponent, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Button, Menu, Popconfirm, Row, Tag, message, Avatar } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';
import { useAnnouncements, useAuth } from '@hooks';
import { IndigoAvatar } from '../../IndigoAvatar';
import Styled from './Navigation.styled';
import menus from '../Menu';
// import Logo from '@assets/img/Nav_Logo_White@3x.png';
import Logo from '@assets/img/Indigo_logo_ERP.png';
import moment from 'moment';

interface INavigationProps {
  collapsed: boolean;
  toggleCollapse: () => void;
}

export const Navigation: FunctionComponent<INavigationProps> = ({
  collapsed,
  toggleCollapse,
}) => {
  const theme = useTheme();
  const [auth, setAuth] = useAuth();
  const location = useLocation();
  const history = useHistory();

  const [announcements, setAnnouncements] = useAnnouncements();
  const { news, broadcast } = announcements.count;

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const { isLoggedIn: _removed, ...user } = auth;

  const _handleLogout = async () => {
    try {
      localStorage.removeItem('idg');
      setAuth({});
      history.push('/login');
    } catch (error) {
      message.error(error?.toString());
    }
  };

  const _handleClick = (label: string) => {
    if (label === 'Announcements' && !!(news + broadcast)) {
      setAnnouncements({ ...announcements, isClickAnnouncements: true });
    }
  };

  React.useEffect(() => {
    const activeKey = location?.pathname?.split('/')?.[1];
    if (!!activeKey) {
      setSelectedKeys([activeKey]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Styled.Sider
      collapsed={collapsed}
      width={theme.sidebar.width}
      className="navigation"
      collapsible
      trigger={null}
    >
      <div style={{ display: 'flex' }}>
        <div className="logo text-center">
          <Link to="/">
            <img
              src={Logo}
              alt="logo"
              className={collapsed ? 'img-logo' : 'img-logo expand-logo'}
            />
          </Link>
        </div>

        <div className="collapse-icon">
          <FontAwesomeIcon
            icon={['fal', collapsed ? 'angle-right' : 'angle-left']}
            onClick={toggleCollapse}
          />
        </div>
      </div>
      <Menu
        style={{ flex: '1 1', overflow: 'auto' }}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={selectedKeys}
        selectedKeys={selectedKeys}
      >
        {menus.map((e, index) =>
          e.subMenu ? (
            <SubMenu
              key={`${e.label}`}
              title={
                <span>
                  {e.icon}
                  <span>{e.label}</span>
                </span>
              }
            >
              {e.subMenu.map((s, i) =>
                s.subMenu ? (
                  <SubMenu
                    key={`${s.label}-${index}`}
                    title={
                      <span>
                        {s.icon}
                        <span className="no-transition">{s.label}</span>
                      </span>
                    }
                  >
                    {s.subMenu.map((ss, ii) => (
                      <Menu.Item key={`${ss.label}-${ii}`}>
                        <Link to={ss.href ? ss.href : ''}>{ss.label}</Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={`${s.label}-${index}`}>
                    <Link to={s.href ? s.href : ''}>
                      {s.icon}
                      <span>{s.label}</span>
                    </Link>
                  </Menu.Item>
                ),
              )}
            </SubMenu>
          ) : (
            <Menu.Item
              key={e.key}
              onClick={() => _handleClick(e.label as string)}
            >
              <Link
                to={e.href ? e.href : ''}
                className={
                  e.label === 'Announcements' ? 'notification-link' : ''
                }
              >
                {e.icon}
                <span>{e.label} </span>
                {e.label === 'Announcements' && !!(news + broadcast) ? (
                  <span
                    className={
                      collapsed
                        ? 'notification-count no-collapsed'
                        : 'notification-count '
                    }
                  >
                    {news + broadcast}
                  </span>
                ) : null}
              </Link>
            </Menu.Item>
          ),
        )}
      </Menu>

      <Styled.Auth>
        <IndigoAvatar
          size={collapsed ? 40 : 32}
          src={user?.avatarUrl}
          style={{ marginLeft: collapsed ? '5px' : '' }}
          icon={<FontAwesomeIcon icon={['far', 'user']} />}
          inline={true}
        />
        <Row align="middle">
          <p
            className={`profile-info ${
              collapsed ? 'profile-info-hide' : 'text'
            }`}
          >
            {`${user?.firstName} ${user?.lastName}`}
          </p>
          <p
            className={`profile-info ${
              collapsed ? 'profile-info-hide' : 'text'
            }`}
          >
            <Tag
              className={collapsed ? 'profile-info-hide' : 'profile-role'}
              color="#24a148"
            >
              {user?.role}
            </Tag>
          </p>
        </Row>
      </Styled.Auth>

      <Popconfirm
        placement="rightTop"
        title={`Are you want to Log out?`}
        onConfirm={_handleLogout}
        okText="Yes"
        cancelText="No"
      >
        <Button className={`logout-btn ${collapsed ? 'icon' : ''}`}>
          {`${collapsed ? '' : 'Log out'} `}
          <FontAwesomeIcon icon={['far', 'sign-out']} />
        </Button>
      </Popconfirm>
      <Styled.Footer
        className={`navigation__footer ${
          collapsed ? 'navigation__footer-hide' : ''
        }`}
      >
        <p className="text">{`© ${moment(new Date()).format(
          'YYYY',
        )} Copyright Indigo Education Group`}</p>
      </Styled.Footer>
    </Styled.Sider>
  );
};

export default Navigation;
