import { Layout } from 'antd';
import styled from 'styled-components';

const { Sider } = Layout;

const Styled: any = {};

Styled.Sider = styled(Sider)`
  min-height: 460px;
  background-color: ${({ theme }) => theme.color.primary};

  .anticon {
    font-size: 20px !important;
  }

  .ant-btn {
    margin: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 40px;
    width: calc(100% - 32px);
    background-color: rgba(#000, 0.22);
    color: ${({ theme }) => theme.color.white};
    border: none;
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logout-btn {
    background-color: #394bd7;
    &.icon {
      min-width: 48px !important;
    }
  }

  .ant-menu-dark {
    background-color: ${({ theme }) => theme.color.primary};

    .ant-menu-inline.ant-menu-sub {
      background-color: ${({ theme }) => theme.color.primary};
    }

    .ant-menu-item {
      &:last-child {
        margin-bottom: 0;
      }
      &-selected {
        background-color: rgba(#000, 0.22);
      }
    }

    .ant-menu-submenu-open {
      color: ${({ theme }) => theme.color.white} !important;
    }
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .ant-menu-inline {
    .ant-menu-inline {
      .ant-menu-inline {
        .ant-menu-item {
          margin-left: -25px !important;
          width: 225px;
        }
      }
    }
  }

  .ant-menu-submenu-popup {
    .ant-menu-sub {
      background-color: ${({ theme }) => theme.color.primary};

      .ant-menu-submenu {
        span {
          color: ${({ theme }) => theme.color.white};
        }
        &-arrow {
          &::after,
          &::before {
            background: ${({ theme }) => theme.color.white};
          }
        }
      }

      .ant-menu-item {
        a {
          color: ${({ theme }) => theme.color.white};
        }
        &:hover {
          color: ${({ theme }) => theme.color.white} !important;
        }
      }
    }

    .ant-menu-item {
      margin: 0;
      li {
        margin: 0;
      }
      &-selected {
        background-color: rgba(#000, 0.22) !important;
      }
      &-active {
        background-color: rgba(#000, 0.22);
      }
      span {
        color: ${({ theme }) => theme.color.white};
      }
    }

    .ant-menu-submenu-selected {
      background-color: rgba(#000, 0.22);
    }

    .ant-menu-submenu-open {
      &.ant-menu-submenu-active {
        .ant-menu-submenu-title {
          color: ${({ theme }) => theme.color.white} !important;
        }
      }
    }
  }

  .collapse-icon {
    width: 20px;
    display: flex;
    color: ${({ theme }) => theme.color.black};
    margin-top: 50px;
    cursor: pointer;
    svg {
      width: 24px !important;
      height: 24px;
      background-color: ${({ theme }) => theme.color.white};
      border-radius: 50%;
      position: absolute;
      z-index: 1000;
      border: 1px solid #2b368b;
      right: -10px;
    }
  }

  .logo {
    width: 244px;
  }

  .img-logo {
    transition: 0.3s ease;
    height: 30px;
    width: 50px;
    margin: 47px auto 32px auto;
  }

  .expand-logo {
    height: 60px !important;
    width: auto;
    margin: 32px auto 32px auto;
  }

  .ant-menu-item .anticon + span,
  .ant-menu-submenu-title .anticon + span {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }

  .notification-link {
    display: flex;
    align-items: center;
    position: relative;
  }

  .ant-menu-item {
    overflow: initial;
  }
  .notification-count {
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ffa629;
    border-radius: 24px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    font-size: 13px;
    justify-content: center;

    &.no-collapsed {
      top: -3px;
      right: -14px;
      height: 20px;
      width: 20px;
      font-size: 11px;
      transform: none;
    }
  }
`;

Styled.Auth = styled.div`
  transition: 0.2s;
  position: relative;
  padding-left: 40px;
  padding: 0 16px 0 56px;
  margin-top: 20px;

  .ant-avatar {
    position: absolute;
    top: 4px;
    left: 16px;
  }

  .ant-row {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:last-child {
        padding-bottom: 4px;
      }
    }
  }

  p {
    margin: 0;

    .ant-tag {
      margin: 0;
    }
  }

  .text {
    font-size: ${({ theme }) => theme.fontSize.base}px;
    color: ${({ theme }) => theme.color.white};
    line-height: 1.2;
    margin-bottom: 4px;
  }

  .profile-info {
    transition: 0.3s ease;
    width: 100%;
  }

  .profile-info-hide {
    opacity: 0;
  }

  .profile-role {
    text-transform: capitalize;
    margin-left: 8px;
    border-radius: 0;
  }
`;

Styled.Footer = styled.div`
  width: 100%;

  .text {
    font-size: ${({ theme }) => theme.fontSize.small}px;
    color: #538dff;
    text-align: center;
    margin-top: 16px;
  }

  &.navigation__footer {
    overflow: hidden;
    max-height: 1000px;
    transition: 0.3s ease;
  }

  &.navigation__footer-hide {
    max-height: 0;
  }
`;

export default Styled;
