import loadable from '@loadable/component';

const TransferClass = loadable(() => import('./TransferClass'));

export default [
  {
    path: '/transfer-class',
    component: TransferClass,
    title: 'Transfer History',
    breadcrumbs: [],
    exact: true,
  },
];
