import {
  IChangeSuspensionStatusReq,
  ICreatePaymentAdviceReq,
  ICreateStudentFamilyInformationStepReq,
  ICreateStudentInformationStepReq,
  ICreateStudentInitialReq,
  ICreateSuspensionReq,
  IUpdateStudentAvatarReq,
  IUpdateStudentReq,
} from '@interfaces/network';
import { apiService } from '@services/api';

const createStudentInitial = async (request: ICreateStudentInitialReq) =>
  await apiService.post(`/student-profiles/init`, request);

const createStudentFamilyInformationStep = async (
  request: ICreateStudentFamilyInformationStepReq,
) => await apiService.post(`student-profiles/step-family-information`, request);

const createStudentInformationStep = async (
  request: ICreateStudentInformationStepReq,
) =>
  await apiService.post(`student-profiles/step-student-information`, request);

const updateStudent = async (request: IUpdateStudentReq) =>
  await apiService.put(`/student-profiles`, request);

const updateStudentAvatar = async (values: IUpdateStudentAvatarReq) =>
  await apiService.patch(
    `student-profiles/${values.id}/avatar`,
    values.request,
  );

const deleteStudent = async (id: string) =>
  await apiService.delete(`/student-profiles/${id}`);

const createPaymentAdviceInitial = async () =>
  await apiService.post(`/finances/payment-advices/init`);

const createPaymentAdvice = async (
  id: string,
  request: ICreatePaymentAdviceReq,
) => await apiService.put(`/finances/payment-advices/${id}`, request);

const createSuspensionInitial = async () =>
  await apiService.post(`/credit-notes`, {
    type: 'suspension',
  });

const updateSuspension = async (id: string, request: ICreateSuspensionReq) =>
  await apiService.put(`/credit-notes/${id}`, request);

const changeSuspensionStatus = async (
  id: string,
  request: IChangeSuspensionStatusReq,
) =>
  await apiService.patch(
    `/credit-notes/change-status-credit-note/${id}`,
    request,
  );

const getRefundDetailStudent = async (id: string) =>
  await apiService.get(`/refund/${id}`);

const createRefundInitial = async () =>
  await apiService.post(`/refund`, {
    type: 'withdrawal',
  });

const getClassInformationNotRefund = async (id: string) =>
  await apiService.get(`/classes`, {
    params: {
      notRefundBy: id,
    },
  });

export {
  createStudentInitial,
  createStudentFamilyInformationStep,
  createStudentInformationStep,
  updateStudent,
  updateStudentAvatar,
  deleteStudent,
  createPaymentAdviceInitial,
  createPaymentAdvice,
  createSuspensionInitial,
  updateSuspension,
  changeSuspensionStatus,
  getRefundDetailStudent,
  createRefundInitial,
  getClassInformationNotRefund,
};
