import {
  IGenerateInvoiceReq,
  IUpdatePaymentVoidedReq,
} from '@interfaces/network';
import { apiService } from '@services/api';

const getPaymentAdvicePaper = async (id: string) =>
  await apiService.get(`/finances/payment-advices/${id}/paper`);

const generateInvoice = async (request: IGenerateInvoiceReq) =>
  await apiService.post(`/finances/invoices`, request);

const updatePaymentVoided = async (request: IUpdatePaymentVoidedReq) =>
  await apiService.post(`/finances/payment-advices/void-unpaid`, request);

const getPaymentAdvicePdf = async (id: string) =>
  await apiService.get(`/finances/payment-advices/download/${id}`);

const getPaymentReceiptPdf = async (id: string) =>
  await apiService.get(`/finances/receipts/download/${id}`);

export {
  getPaymentAdvicePaper,
  generateInvoice,
  updatePaymentVoided,
  getPaymentAdvicePdf,
  getPaymentReceiptPdf,
};
