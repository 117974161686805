import loadable from '@loadable/component';

const Announcements = loadable(() => import('./pages/Announcements'));
const News = loadable(() => import('./pages/News'));
export default [
  {
    path: '/announcements',
    component: Announcements,
    title: 'Announcements',
    breadcrumbs: [],
    exact: true,
  },
  {
    path: '/announcements/:id',
    component: News,
    title: 'News',
    breadcrumbs: [],
    exact: true,
  },
];
