import loadable from '@loadable/component';

// const PayBill = loadable(() => import('./PayBill'));
const BillingPage = loadable(() => import('./pages/BillingPage'));
// const BillDetail = loadable(() => import('./BillDetail'));
const PaymentAdvicePaper = loadable(() => import('./pages/PaymentAdvicePaper'));
const ReceiptPaper = loadable(() => import('./pages/ReceiptPaper'));

export default [
  {
    path: '/pay-bill',
    component: BillingPage,
    title: 'Payment Advice',
    breadcrumbs: [],
    exact: true,
  },
  {
    path: '/pay-bill/:id',
    component: PaymentAdvicePaper,
    title: 'Payment Advice Details',
    breadcrumbs: [],
    exact: true,
  },
  {
    path: '/receipt',
    component: BillingPage,
    title: 'Receipt',
    breadcrumbs: [],
    exact: true,
  },
  {
    path: '/receipt/:id',
    component: ReceiptPaper,
    title: 'Receipt Details',
    breadcrumbs: [],
    exact: true,
  },
  // {
  //   path: '/pay-bill',
  //   component: PayBill,
  //   title: 'Billing Information',
  //   breadcrumbs: [],
  //   exact: true,
  // },
  // {
  //   path: '/pay-bill/:invoiceId',
  //   component: BillDetail,
  //   title: 'Bill Details',
  //   breadcrumbs: [],
  //   exact: true,
  // },
];
