import loadable from '@loadable/component';

const ClassListSchedule = loadable(() => import('./ClassListSchedule'));
const ClassDetails = loadable(() => import('./ClassDetails'));

export default [
  {
    path: '/classes',
    component: ClassListSchedule,
    title: 'Class List',
    breadcrumbs: [],
    exact: true,
  },
  {
    path: '/classes/:classId/:terms',
    component: ClassDetails,
    title: 'Class Details',
    breadcrumbs: [],
    exact: true,
  },
];
