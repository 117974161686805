import { Layout } from 'antd';
import styled from 'styled-components';
const { Header } = Layout;

const Styled: any = {};

Styled.Header = styled(Header)<{ collapsed: boolean; line: string }>`
  &.header-wrap {
    background-color: ${({ theme }) => theme.color.white};
    padding: 0;
    width: ${({ collapsed, theme }) =>
      collapsed
        ? 'calc(100% - 80px)'
        : `calc(100% - ${theme.sidebar.width}px)`};
    height: 60px;
    position: fixed;
    top: 0;
    right: 0;
    padding-right: 20px;
    padding-left: 15px;
    z-index: 888;
    transition: width 0.2s;
    display: flex;
    border-bottom: ${({ theme }) => theme.color.line} 1px solid;
  }

  .breadcrumb-wrapper {
    display: flex;
    align-items: center;
  }

  .breadcrumb-page {
    color: ${({ theme }) => theme.color.primary};
    font-weight: bold;
    font-size: 18px;
    padding-right: 20px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      height: 15px;
      width: 2px;
      background-color: ${({ theme }) => theme.color.primary};
      display: ${({ line }) => (line === 'exist' ? 'block' : 'none')};
      transform: translateY(-50%);
    }
  }
`;

export default Styled;
