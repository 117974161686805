import { createGlobalStyle } from 'styled-components';

const fontRegular = 'Inter-Regular';
const fontBold = 'Inter-SemiBold';

const fontSizeBase = '14px';
const fontSizeMedium = '18px';

const widthContainer = '1240px';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  h3 {
    font-size: 18px;
  }

  html,
  body {
    font-size: ${fontSizeBase};
    font-family: ${fontRegular};
    background: rgb(240, 242, 245);
  }

  a {
    text-decoration: none;
  }

  strong {
    font-family: ${fontBold};
  }

  .page-container {
    width: 100%;
    margin: auto;
  }

  .container {
    width: 100%;
    max-width: ${widthContainer};
    margin: auto;
  }

  .table-row-light {
    background-color: #ffffff;
  }

  .table-row-dark {
    background-color: #f5f5f5;
  }

  .ant-table tbody > tr.ant-table-row:hover > td {
    background-color: #e1ebff33 !important;
  }

  .page {
    &-title {
      font-family: ${fontBold};
      font-size: ${fontSizeMedium};
      color: #000000;
      margin: 0;
    }
    &__header-line {
      padding-bottom: 16px;
      border-bottom: 1px solid #d8dce6;
      margin-bottom: 32px;
      min-height: 65px;
      align-items: center;
    }
  }

  .flex {
    display: flex;
    &.justify-space {
      justify-content: space-between;
    }
  }

  .ant-btn {
    font-family: ${fontBold};
    font-size: ${fontSizeBase};
    &-default {
      background-color: #f0f2f5 !important;
      color: #000 !important;
    }
  }

  .ant-select-selection-placeholder {
    font-family: ${fontRegular};
    font-size: ${fontSizeBase};
    color: #828894;
    opacity: 1 !important;
  }

  .form-content {
    max-width: 524px;
    padding: 15px;
    margin: auto;

    &__group-name {
      font-family: ${fontBold};
      font-size: ${fontSizeBase};
      color: #000;
    }

    &__footer {
      margin-top: 24px;
      text-align: right;
      .ant-btn + .ant-btn {
        margin-left: 20px;
      }
    }

    &__label {
      font-family: ${fontRegular};
      font-size: ${fontSizeBase};
      color: #828894;
    }

    .ant-form-item {
      margin-bottom: 20px;
    }

    .ant-form-item-label {
      > label {
        font-family: ${fontRegular};
        font-size: ${fontSizeBase};
        color: #828894;
        height: auto;
        &::before {
          display: none;
        }
      }
      > label.ant-form-item-required::before {
        display: none !important;
      }
    }

    .ant-input-number-handler-wrap {
      display: none;
    }

    .ant-select-multiple .ant-select-selection-item {
      background-color: #828894;
      color: #ffffff;
      svg {
        fill: #fff;
      }
    }
  }

  .table-container {
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
      0px 1px 1px -1px rgba(130, 136, 148, 0.48);
    border-radius: 4px;
    thead > tr > th {
      background-color: #fff;
      font-weight: bolder !important;
      font-family: ${fontBold};
    }
  }

  .btn-sample-1 {
    color: #538dff !important;
    width: 120px;
    background: rgb(255, 255, 255) !important;
    background-color: rgb(255, 255, 255) !important;
    box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
      0px 1px 1px -1px rgba(130, 136, 148, 0.48);
    border-radius: 4px;

    &:disabled {
      background: rgb(255, 255, 255);
      border: none;
      color: #828894 !important;
    }
  }

  .indigo-filter-header {
    .ant-form-item-label {
      > label {
        height: auto;
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .ant-form-item {
      margin-bottom: 0px;
    }

    .applied-btn {
      color: #538dff !important;
      width: 120px;
      background: rgb(255, 255, 255) !important;
      background-color: rgb(255, 255, 255) !important;
      box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
        0px 1px 1px -1px rgba(130, 136, 148, 0.48);
      border-radius: 4px;

      &:disabled {
        background: rgb(255, 255, 255);
        border: none;
        color: #828894 !important;
      }
    }

    .ant-select-multiple .ant-select-selection-item {
      background-color: #828894;
      color: #ffffff;
      svg {
        fill: #fff;
      }
    }
  }

  .indigo-divider-header {
    margin: 12px 0px !important;
  }

  .indigo-hide {
    display: none;
  }

  .indigo-tab {
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #538dff !important;
        font-weight: bold !important;
      }
    }
    .ant-tabs-tab {
      padding-left: 15px !important;
      padding-right: 15px !important;
      font-weight: bold !important;
    }
    .ant-tabs-ink-bar {
      background-color: #538dff;
    }
  }

  .ant-modal-title {
    font-size: 18px !important;
    font-family: ${fontBold} !important;
    font-weight: bold !important;
  }

  .ant-modal-close-x {
    font-size: 18px !important;
  }

  // .fixed-width-btn {
  //   width: 97px !important;
  // }
  .ant-btn {
    height: 48px !important;
    min-width: 97px !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #2b368b !important;
    > .ant-steps-icon {
      color: #fff !important;
    }
  }

  .ant-steps-item-tail::after {
    height: 3px !important;
    background-color: #2b368b !important;
  }

  .ant-steps-item-process .ant-steps-item-title {
    font-family: ${fontBold} !important;
    font-weight: bold !important;
    font-size: 14px !important;
  }

  .ant-steps-item-finish .ant-steps-item-title {
    color: #000 !important;
  }

  .ant-steps-item-finish .ant-steps-item-title {
    font-family: ${fontBold} !important;
    font-weight: bold !important;
    font-size: 14px !important;
  }

  .text-bold {
    font-weight: bold;
    font-family: ${fontBold};
  }

  .btn-underline {
    text-decoration: underline !important;
    span {
      text-decoration: underline !important;
    }
  }

  .text-clickable {
    cursor: pointer !important;
    &[disbaled] {
      cursor: default !important;
    }
  }

  .text-underline {
    text-decoration: underline !important;
  }

  .field-underline {
    &:hover {
      cursor: pointer !important;
      &[disbaled] {
        cursor: default !important;
      }
      text-decoration: underline !important;
    }
  }

  .btn-default {
    border: none;
    background-color: transparent;
    outline: none;
    &[disabled] {
      cursor: default;
    }
  }

  .clickable {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    font-family: ${fontBold};
    text-decoration: underline !important;
    cursor: pointer !important;
    &[disabled] {
      cursor: default !important;
    }
  }

  .relative {
    position: relative;
  }

  .text {
    font-family: ${fontRegular};
    font-size: ${fontSizeBase};
    color: #000000;

    &--bold {
      font-family: ${fontBold};
      font-size: ${fontSizeBase};
      color: #000000;
    }

    &--large {
      font-family: ${fontBold};
      font-size: ${fontSizeMedium};
      color: #000000;
    }
    
    &--label {
      font-family: ${fontRegular};
      font-size: ${fontSizeBase};
      color: #828894;
    }
    &--capitalize {
      text-transform: capitalize;
    }
  }

  .text-danger {
    color: #f54f64 !important;
  }

  .text-info {
    color: #538dff !important;
  }

  .text-primary {
    color: #2b368b !important;
  }

  .text-success {
    color: #65db75 !important;
  }

  .text-warning {
    color: #ffa629 !important;
  }

  .text-purple {
    color: #6d48e5 !important;
  }

  .text-ink {
    color: #000 !important;
  }

  .text-secondary {
    color: #828894 !important;
  }

  .text-white {
    color: #fff !important;
  }

  .table-clickable {
    tbody > tr {
      cursor: pointer !important;
      &[disbaled] {
        cursor: default !important;
      }
    }
  }

  .create-student-form {
    .form-group-title {
      font-family: ${fontBold};
      font-size: ${fontSizeMedium};
      line-height: 20px;
    }
    .form-group-content {
      margin-top: 20px;
    }
    .ant-form-item-label {
      > label {
        height: auto;
      }
      > label.ant-form-item-required::before {
        display: none;
      }
    }
    &__link {
      font-family: ${fontBold};
      font-size: ${fontSizeBase};
      text-decoration: underline;
    }
    .ant-btn-link {
      padding: 0;
      height: auto;
      > span {
        text-decoration: underline;
      }
    }
    &.pa {
      .ant-btn-link {
        height: 48px;
      }
    }
  }

  .form-group-title {
    font-family: ${fontBold};
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .form-group-border {
    position: relative;
    padding: 20px;
    border: 1px solid rgb(216, 220, 230);
    + .form-group-border {
      margin-top: 20px;
    }
  }

  .button-black-text {
    color: #000 !important;
  }

  .btn-danger {
    background: #f54f64 !important;
    border: none !important;
  }

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    width: 100%;
  }

  .text-left {
    text-align: left !important;
  }

  .text-center {
    text-align: center !important;
  }

  .text-right {
    text-align: right !important;
  }

  .text-small {
    font-size: 12px !important;
  }

  .bg-white {
    background: #fff !important;
  }

  .ant-tag {
    &.idg-tag {
      &--primary {
        font-family: ${fontRegular};
        font-size: 12px;
        color: #fff;
        background-color: #538dff;
        border-radius: 18px;
        height: 24px;
        line-height: 24px;
        border-width: 0;
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }

  .input--noborder.ant-input,
  .input--noborder {
    border: 2px solid transparent !important;
    &:hover,
    &:focus {
      border: 2px solid rgb(83, 141, 255) !important;
    }
  }

  .reset-filter-btn {
    .reset-filter-icon {
      margin-right: 5px;
    }
  }

  .pill-wrapper-flex {
    display: flex;
    justify-content: flex-end;
  }

  @media print {
    .noPrint {
      visibility: hidden;
    }
  }

  .ql-editor {
    min-height: 100px !important;
  }

  .editor-btns {
    .ant-btn {
      color: rgba(0, 0, 0, 0.65) !important;
      background-color: #f0f2f5 !important;
      &.ant-btn-primary {
        color: #fff !important;
        background-color: #2b368b !important;
        border-color: #2b368b !important;
      }
    }
  }

  .currency-input {
    -webkit-appearance: none;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    &:hover {
      border-color: #c0c4cc;
    }
    &:focus {
      outline: none;
    }
    &.currency-input-disble {
      background-color: #f5f7fa;
      border-color: #e4e7ed;
      color: #c0c4cc;
      cursor: not-allowed;
    }
  }

  .student-option-item {
    padding-left: 40px;
    height: 38px;
    line-height: 38px;
  }

  .student-card {
    padding: 16px;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    border: 1px dashed rgb(216, 220, 230);
    width: 380px;

    .label,
    .text {
      margin: 0;
    }

    .flex {
      align-items: center;

      + .flex {
        margin-top: 16px;
      }
    }
  }

  .siblings-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-top: -20px;

    .student-card {
      margin-left: 20px;
      margin-top: 20px;
    }
  }

  .credit-note {
    &__status {
      margin: 0;
      font-size: 12px;
      border-radius: 12px;
      text-align: center;
      padding: 3px 12px;
      display: inline-block;
      &.awaiting_finance_action {
        color: #ffffff;
        background-color: #fa73c4;
      }
      &.awaiting_front_desk_action {
        color: #ffffff;
        background-color: rgb(173, 153, 240);
      }
      &.awaiting_finance_approval {
        color: #ffffff;
        background-color: #5064ff;
      }
      &.rejected {
        color: #ffffff;
        background-color: rgb(245, 79, 100);
      }
      &.used {
        color: #ffffff;
        background-color: #ffa629;
      }
      &.issued {
        color: #ffffff;
        background-color: rgb(83, 141, 255);
      }
      &.voided {
        color: #ffffff;
        background-color: rgb(130, 136, 148);
      }
      &.refunded {
        color: #ffffff;
        background-color: #538dff;
      }
    }
    &__general-info {
      margin-bottom: 40px;
      .text--label {
        margin-bottom: 6px;
      }
    }
  }

  .lesson-form-session {
    border: 1px solid #d8dce6;
    padding: 20px;
    position: relative;
    + .lesson-form-session {
      margin-top: 20px;
    }
    &__row {
      margin-top: 20px;
    }
    &__name {
      margin: 0;
      font-family: ${fontBold};
      font-size: ${fontSizeBase};
      color: #000;
    }
    &__label {
      font-size: 12px;
      color: #828894;
    }
    &__btn-delete {
      &.ant-btn {
        position: absolute;
        top: 8px;
        right: 20px;
        padding: 0;
        height: 36px;
        line-height: 36px;
        font-family: ${fontBold};
        font-size: ${fontSizeBase};
        z-index: 2;
      }
    }
    .flex {
      display: flex;
      &-space-between {
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .secondary-header {
    font-size: 18px !important;
    font-weight: bold;
  }

  .ant-form-item-required::before {
    display: none !important;
  }
  .ant-btn[disabled] {
    cursor: default !important;
  }

  .center-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-capitalized {
    text-transform: capitalize;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .font-bold {
    font-family: ${fontBold};
    font-weight: bolder;
  }

  .ml-auto {
    margin-left: auto;
  }

  .action-btn-menu {
    padding: 0;
    li {
      padding: 10px 25px;
      color: black;
      transition: 0.3s ease;
      &:hover {
        background-color: #c0c3cc;
      }
    }
  }

  .action-btn {
    > svg {
      margin-left: 0.5rem;
    }
  }

  .check-circle {
    background-color: #68d977;
    height: 50px;
    width: 50px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.failed {
      background-color: #f00;
    }
  }

  .circle-check-cont {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .paynow-img {
    height: auto;
    width: 150px;
    margin-bottom: 1rem;
    margin-top: 5px;
  }

  .creat-student-family-info-cont {
    padding-left: 15px;
    padding-right: 15px;
  }

  .create-studen-info-cont {
    margin: 15px 20px;
  }

  .mt-1 {
    margin-top: 1rem !important;
  }

  .mt-2 {
    margin-top: 2rem !important;
  }

  .mt-3 {
    margin-top: 3rem !important;
  }
  .mr-1 {
    margin-right: 1rem !important;
  }
  .mr-2 {
    margin-right: 2rem !important;
  }
  .mr-3 {
    margin-right: 3rem !important;
  }

  .p-0 {
    padding: 0 !important;
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .credit-note-text {
    background-color: #d8dce6;
    padding: 12px;
    border-radius: 4px;
    display: inline-block;
    min-width: 120px;
  }

  .ant-table-thead > tr > th:first-child,
  .ant-table-thead > tr > td:first-child,
  .ant-table-tbody > tr > th:first-child,
  .ant-table-tbody > tr > td:first-child,
  .ant-table-tfoot > tr > th:first-child,
  .ant-table-tfoot > tr > td:first-child {
    padding-left: 30px !important;
  }

  .ant-table-thead > tr > th:last-child,
  .ant-table-thead > tr > td:last-child,
  .ant-table-tbody > tr > th:last-child,
  .ant-table-tbody > tr > td:last-child,
  .ant-table-tfoot > tr > th:last-child,
  .ant-table-tfoot > tr > td:last-child {
    padding-right: 30px !important;
  }
`;
