import loadable from '@loadable/component';
const Attendance = loadable(() => import('./Attendance'));
export default [
  {
    path: '/attendance',
    component: Attendance,
    title: 'Attendance',
    breadcrumbs: [],
    exact: true,
  },
  {
    path: '/attendance/:branch',
    component: Attendance,
    title: 'Attendance',
    breadcrumbs: [],
    exact: true,
  },
];
