import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import loginRoute, { changePasswordRoute } from '@features/Auth/routes';
import PrivateRoute from './PrivateRoute';
import React from 'react';
import { Spin } from 'antd';
import announcementsRoutes from '@features/Announcements/routes';
import attendanceRoute from '@features/Attendance/routes';
import billingRoutes from '@features/Billing/routes';
import classRoutes from '@features/Class/routes';
import { createBrowserHistory } from 'history';
import { downloadPdf } from '@features/DownloadPdf/routes';
// import graduateClassRoutes from '@pages/graduateClass/routes';
// import notFoundRoutes from '@pages/notfound/routes';
import studentRoutes from '@features/Profile/routes';
import transferClassRoutes from '@features/TransferHistory/routes';
import { useAuth, withAuth } from '@hooks';

const history = createBrowserHistory();

export interface IRoute {
  path: string;
  component: any;
  title: string;
  breadcrumbs: any[];
  exact: boolean;
}

const privateRoutes: IRoute[] = [
  ...billingRoutes,
  ...studentRoutes,
  ...announcementsRoutes,
  ...classRoutes,
  ...transferClassRoutes,
  // ...graduateClassRoutes,
  // ...notFoundRoutes,
];

function Routes() {
  const [{ isLoggedIn }] = useAuth();

  const { pathname } = history.location;

  const redirectPath = ['/login', '/forgot-password'].includes(pathname)
    ? '/classes'
    : pathname;
  return (
    <React.Suspense fallback={<Spin />}>
      <Router>
        <Switch>
          {[...loginRoute, ...attendanceRoute].map(
            ({ path, exact, component: Component }) => (
              <Route key={path} exact={exact} path={path}>
                {isLoggedIn ? <Redirect to={redirectPath} /> : <Component />}
              </Route>
            ),
          )}
          {changePasswordRoute.map(({ path, component: Component }) => (
            <Route path={path} key={path} exact>
              <Component />
            </Route>
          ))}
          {downloadPdf.map(({ path, exact, component: Component }) => (
            <Route path={path} key={path} exact={exact}>
              <Component />
            </Route>
          ))}
          {privateRoutes.map(({ component: Component, title, ...rest }) => (
            <PrivateRoute
              {...rest}
              key={rest.path}
              title={title}
              isLoggedIn={isLoggedIn}
              privateRoutes={privateRoutes}
            >
              <Component />
            </PrivateRoute>
          ))}
          {<Redirect from="*" to={isLoggedIn ? '/classes' : '/login'} />}
        </Switch>
      </Router>
    </React.Suspense>
  );
}

export default withAuth(Routes);
